import { useContext, useEffect, useState } from 'react'
import { ProductContext } from '../context/addProductContext'
import useWindowResize from './useWindowResize'
import uniq from 'lodash.uniq'
const windowGlobal = typeof window !== 'undefined' && window
const isWindow = typeof window !== 'undefined'

const useProduct = () => {
  const [table, setTable] = useContext(ProductContext)
  const { height, width } = useWindowResize();

  const addToTable = (slug, tag, item) => {
    if ( item === 'defaultModel' ) {
      return false
    }
    const sessions = JSON.parse(
      windowGlobal.sessionStorage.getItem(slug === 'dolphin' ? tag : slug)
    )
    
    
    if ( width > 1023 ) {
      const mixedData = [...sessions, item]
      if ( mixedData.length > 3 ) {
        alert('Items to compare cannot be more than 3.')
      } else {
        const uniqueData = uniq(mixedData)
        setTable(uniqueData)
        windowGlobal.sessionStorage.setItem(
          slug === 'dolphin' ? tag : slug,
          JSON.stringify(uniqueData)
        )
      }
    } else {
      const mixedData = [...sessions, item]
      if (mixedData.length > 2) {
        alert('Items to compare cannot be more than 2.')
      } else {
        const uniqueData = uniq(mixedData)
        setTable(uniqueData)
        windowGlobal.sessionStorage.setItem(
          slug === 'dolphin' ? tag : slug,
          JSON.stringify(uniqueData)
        )
      }
    }
  }

  const dataSummary = (distinction) => {
    const tabData = windowGlobal.sessionStorage.getItem(distinction)
    return tabData
  }

  const tabDataSummary = (distinction) => {
    const tabData = windowGlobal.sessionStorage.getItem(distinction)
    return tabData
  }

  return {
    dataSummary,
    tabDataSummary,
		addToTable,
	}
}

export default useProduct
