import React from 'react'
import PropTypes from 'prop-types'
const Rectangular = ({ size, color, ...otherProps }) => (
  <svg
    id="Rectangular Pool"
    aria-hidden="true"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Rectangular Pool"
    width={size ? size : ''}
    viewBox="0 0 62.89 104.58"
    {...otherProps}
  >
    <title>Round Pool</title>
    <g id="Layer_2" data-name="Layer 2">
        <g id="Wizard">
            <path fill="#384152" class="cls-1" d="M62.88,52.29q0,25.75,0,51.52c0,.61-.14.77-.76.77q-30.69,0-61.37,0c-.62,0-.76-.16-.76-.77Q0,52.26,0,.7C0,.13.13,0,.69,0Q31.41,0,62.13,0c.61,0,.76.15.76.76Q62.86,26.53,62.88,52.29Zm-2.61,0V3.6c0-1,.11-.88-.84-.88q-27.88,0-55.76,0c-1,0-.91-.13-.91.88q0,48.69,0,97.38c0,1,0,1,1,1H59.36c.91,0,.91,0,.91-.91Z"/>
            <path fill={color} class="cls-2" d="M60.27,52.29v48.76c0,.91,0,.91-.91.91H3.73c-1,0-1,0-1-1q0-48.69,0-97.38c0-1-.13-.88.91-.88q27.87,0,55.76,0c1,0,.84-.12.84.88Z"/>
        </g>
    </g>
  </svg>
)
Rectangular.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
}
Rectangular.defaultProps = {
  size: '',
  color: "#c3c3c3",
}
export default Rectangular
